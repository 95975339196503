import axios from "axios"
import AlertServie from "./errors"

const ApiService = {
  baseUrl: "https://blendbox.tdzone.net:1337/api/v1",
  korekBaseUrl: "https://korek-he.trendy-technologies.com",
  headers: {
    Authorization: "Bearer " + localStorage.getItem("BlendBox"),
    lang: localStorage.getItem("lang")
  },
  init() {
    localStorage.setItem("Lang", "en")

    axios.interceptors.response.use(
      response => response,
      error => {
        const status = error.response ? error.response.status : null

        if (status === 401) {
          localStorage.removeItem("loggedIn")
          window.location.href = "/login"
        } else if (status === 422) {
          error.response.data.errors.map(function (value, key) {
            AlertServie.errorMessage(error.response.data.errors[key][0])
          })
        } else if (status === 402) {
          AlertServie.errorMessage(error.response.data.msg)
        } else if (status === 400) {
          AlertServie.errorMessage(error.response.data.message)
        } else {
          AlertServie.errorMessage("Error")
        }

        return Promise.reject(error)
      }
    )
  },
  changeLAng() {
    this.headers = {
      Authorization: "Bearer " + localStorage.getItem("BlendBox"),
      lang: "en"
    }
  },
  async generateScript(uuid, timestamp) {
    delete axios.defaults.headers.common.lang
    try {
      const response = await axios.get(
        this.korekBaseUrl +
          "/dcbprotect.php?action=script&ti=" +
          uuid +
          "&ts=" +
          timestamp +
          "&te=%23sub_btn&servicename=Entertainia&merchantname=Tornet"
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async login(phoneNumber, ti) {
    axios.defaults.headers.common.lang = "en"

    try {
      const response = await axios.post(this.baseUrl + "/auth/login", {
        phoneNumber: phoneNumber,
        ti: ti
      })
      return response
    } catch (e) {
      return e.response
    }
  },

  async verify(phoneNumber, otp, ts, ti) {
    try {
      const response = await axios.post(this.baseUrl + "/auth/verify", {
        phoneNumber: phoneNumber,
        pincode: otp,
        ts: ts,
        ti: ti
      })
      return response
    } catch (e) {
      return e.response
    }
  },

  async checkUserStatus(phoneNumber) {
    try {
      const response = await axios.post(
        this.baseUrl + "/auth/check-user-status",
        {
          phoneNumber: phoneNumber
        }
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async getSetting(id) {
    try {
      const response = await axios.get(
        this.baseUrl + `/site/getData?category_id=${id}`
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async getCategories(categoryId) {
    try {
      const response = await axios.get(
        this.baseUrl + `/categories/${categoryId}/subcategories`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("BlendBox")
          }
        }
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async getLastContentCategories(categoryId) {
    try {
      const response = await axios.get(
        this.baseUrl +
          `/content/content-per-category?content_type=Image&category_id=${categoryId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("BlendBox")
          }
        }
      )
      return response
    } catch (e) {
      return e.response
    }
  },
  async getMainCategories() {
    try {
      const response = await axios.get(this.baseUrl + "/categories", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("BlendBox")
        }
      })
      return response
    } catch (e) {
      return e.response
    }
  },

  async getContentByCategory(category) {
    try {
      const response = await axios.get(
        this.baseUrl + "/content/list-by-category?category_id=" + category,
        {
          headers: this.headers
        }
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async openContent(contentID) {
    try {
      const response = await axios.get(
        this.baseUrl + "/content/openit/" + contentID,
        {
          headers: this.headers
        }
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async myContents(categoryId) {
    try {
      const response = await axios.get(
        this.baseUrl + "/content/subscriber-contents?category_id=" + categoryId,
        {
          headers: this.headers
        }
      )
      return response
      // alert(response)
    } catch (e) {
      return e.response
    }
  },

  async getRamadanCategories() {
    try {
      const response = await axios.get(
        this.baseUrl + "/ramadan/category/list",
        {
          headers: this.headers
        }
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async getRamadanContentByCategory(categoryId) {
    try {
      const response = await axios.get(
        this.baseUrl + `/ramadan/category/${categoryId}/contents`,
        {
          headers: this.headers
        }
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async openRamadanContent(contentID) {
    try {
      const response = await axios.get(
        this.baseUrl + "/ramadan/openit/" + contentID,
        {
          headers: this.headers
        }
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async myRamadanContents() {
    try {
      const response = await axios.get(
        this.baseUrl + "/ramadan/subscriber/contents",
        {
          headers: this.headers
        }
      )
      return response
      // alert(response)
    } catch (e) {
      return e.response
    }
  },

  async olimob_send_token(token) {
    try {
      const response = await axios.get(this.baseUrl + "/olimob/user", {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      return response
    } catch (e) {
      return e.response
    }
  }
}

export default ApiService
