import Vue from "vue"
import VueToast from "vue-toast-notification"
import "vue-toast-notification/dist/theme-bootstrap.css"

const AlertServie = {
  init() {
    Vue.use(VueToast)
  },
  info(title) {
    Vue.$toast.open({
      message: title,
      type: "info",
      position: "top"
    })
  },
  successMessage(title) {
    Vue.$toast.open({
      message: title,
      type: "success",
      position: "top"
    })
  },
  errorMessage(title) {
    Vue.$toast.open({
      message: title,
      type: "error",
      position: "top"
    })
  }
}

export default AlertServie
