<template>
  <main v-if="loading != true" class="main-section" style="direction: rtl">
    <div class="head text-center">
      <div v-show="surahNo !== ''" class="row d-flex justify-content-between">
        <div class="col-sm-4 col-5">
          <h5 class="info-3 m-0 main-color-two" style="font-family: 'Speda'">
            عدد الآيات : {{ numberOfAyahs }}
          </h5>
        </div>

        <div class="col-sm-4 col-5">
          <h5 class="info-1 m-0">
            <!-- <span class="number main-color-two d-inline-block me-2">{{ surahNo }}</span> -->
            <span
              class="name quran_font main-color-one"
              style="font-size: 15px"
              >{{ surahName }}</span
            >
          </h5>
        </div>
        <!-- <div class="col-sm-4 col-2">
            <h5 class="info-2 m-0 main-color-one">{{ revelationType === 'Meccan' ? 'مكية' : 'مدنية' }}</h5>
        </div> -->

        <div class="col-sm-4 col-2 back-button-parent">
          <button class="back-button" @click="goBack">
            <i class="fas fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- <div class="actions mt-3">
        <div class="row">

          <div class="col-md-4">
                <select id="write-type-selector" class="form-select w-75 m-auto mb-md-0 mb-2 main-color-one shadow-none"
                    @change="getAyahsWriteType($event.target.value, $event.target.options[$event.target.selectedIndex].text)">
                    <option value="">جۆری نوسینەکە هەڵبژێرە</option>
                    <option v-for="type in writeTypes.filter(wType => !noNeededWriteType.includes(wType.identifier))"
                        :value="type.identifier">
                        {{ type.name }}
                    </option>
                </select>
            </div>
             <div class="col-md-4">
                <select id="tafsir-selector"
                    class="form-select form-control w-75 m-auto mb-md-0 mb-2 main-color-one shadow-none"
                    @change="getAyahsTafsir($event.target.value, $event.target.options[$event.target.selectedIndex].text)">
                    <option value="">تەفسیر هەڵبژێرە</option>
                    <option v-for="tafsir in tafsirs" :value="tafsir.identifier">
                        {{ tafsir.name }}
                    </option>
                </select>

            </div>
        </div>
    </div> -->

    <div id="player-container" class="player-container mt-3">
      <div class="row">
        <div class="col-lg-5 col-md-6 mb-3">
          <audio
            id="player"
            :current-ayah="currentAyah"
            class="d-block w-100 mb-2"
            controls
            @ended="switchAudio"
            @playing="scrollToActiveAyah(currentAyah)"
          >
            <source src="" type="audio/mpeg" />
          </audio>
        </div>

        <div class="col-lg-12 col-md-3">
          <div class="d-flex flex-wrap justify-content-center">
            <div class="col-sm-4 col-12">
              <select
                class="form-select main-color-one shadow-none mb-2 responsive-font-size custom-select-black"
                @change="changeReader($event.target.value)"
              >
                <option
                  v-for="(reader, index) in readers.data"
                  :key="index"
                  :selected="reader.identifier === selectedReader"
                  :value="reader.identifier"
                >
                  {{ reader.name }}
                </option>
              </select>
            </div>
            <div class="col-sm-4 col-12">
              <select
                class="form-select main-color-one shadow-none mb-2 ketab-font custom-select-black"
                @change="getSurahAyahs($event.target.value)"
              >
                <option
                  v-for="(surah, index) in surahs.data"
                  :key="index"
                  :selected="$route.params.id == surah.number"
                  :value="surah.number"
                >
                  {{ surah.number }} -
                  {{ surah.name }}
                </option>
              </select>
            </div>

            <div class="col-sm-4 col-12">
              <select
                id="translations-selector"
                class="form-select w-75 m-auto mb-md-0 mb-2 kurdish-font main-color-one shadow-none custom-select"
                @change="
                  getAyahsTranslations(
                    $event.target.value,
                    $event.target.options[$event.target.selectedIndex]
                  )
                "
              >
                <option value="">مترجم</option>
                <div v-if="translations !== []">
                  <optgroup
                    v-for="(langName, langKey) in languages"
                    :key="langKey"
                    :label="langName"
                  >
                    <option
                      v-for="(translation, index) in translations.filter(
                        trans => trans.language === langKey
                      )"
                      :key="index"
                      :data-direction="translation.direction"
                      :value="translation.identifier"
                    >
                      {{ translation.name }}
                    </option>
                  </optgroup>
                </div>
              </select>
            </div>
          </div>
        </div>

        <!--
        <div class="col-lg-2 col-md-3">
            <div class="row">
                <div class="col-sm-12 col-6">
                    <select class="form-select mb-2 main-color-one shadow-none responsive-font-size"
                        @change="changeAudioQuality($event.target.value)">
                        <option value="high"
                            :selected="$cookies.get('audio-quality') === 'high' || $cookies.get('audio-quality') === null">
                            کوالێتی دەنگی بەرز
                        </option>
                        <option value="low" :selected="$cookies.get('audio-quality') === 'low'">جودة الصوت منخفضة
                        </option>
                    </select>
                </div> -->

        <!-- <div class="col-sm-12 col-12">
            <div class="form-check custom-checkbox d-flex">

                <div>
                    <input class="form-check-input" type="checkbox" value="on" id="trackingAyah"
                        :checked="$cookies.get('tracking-ayah') === 'true'"
                        @change="trackingAyah($event.target.checked)">
                </div>

                <div class="col-12 mx-3">
                    <label class="form-check-label main-color-one kurdish-font" for="trackingAyah">
                        شوێنپێی ئەو ئایەتە بگرە کە دەخوێندرێتەوە
                    </label>
                </div>

            </div>
        </div> -->
      </div>
    </div>

    <!-- <div class="col-lg-2 col-md-12">
        <div class="row">
            <div class="col-lg-12 col-sm-4 col-6">
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="repeatType" id="repeatAllSurahs" value="all"
                        @change="changeRepeatScope($event.target.value)"
                        :checked="$cookies.get('repeat-scope') === 'all' || $cookies.get('repeat-scope') === null">
                    <label class="form-check-label main-color-one" for="repeatAllSurahs">
                        هەموو سورەتەکان دووبارە بکەرەوە
                    </label>
                </div>
            </div>
            <div class="col-lg-12 col-sm-4 col-6">
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="repeatType" id="repeatCurrentSurah" value="surah"
                        @change="changeRepeatScope($event.target.value)"
                        :checked="$cookies.get('repeat-scope') === 'surah'">
                    <label class="form-check-label main-color-one" for="repeatCurrentSurah">
                        سورەتی ئێستا دووبارە بکەرەوە
                    </label>
                </div>
            </div>
            <div class="col-lg-12 col-sm-4 col-6">
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="repeatType" id="repeatCurrentAyah" value="ayah"
                        @change="changeRepeatScope($event.target.value)"
                        :checked="$cookies.get('repeat-scope') === 'ayah'">
                    <label class="form-check-label main-color-one" for="repeatCurrentAyah">
                        ئایەتی ئێستا دووبارە بکەرەوە
                    </label>
                </div>
            </div>
            <div class="col-lg-12 col-sm-4 col-6">
                <go-to-top-btn v-show="showScrollGoToTopBtn"></go-to-top-btn>
            </div>
        </div>
    </div> -->

    <div id="body" class="mt-3">
      <h3
        v-if="$route.params.id !== '9' && !fetchSurahFailed && !loading"
        class="quran_font text-center main-color-one mb-5"
      >
        بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ
      </h3>
      <div v-if="!fetchSurahFailed && !loading">
        <div
          v-for="(ayah, index) in ayahs"
          :id="ayah.number"
          :key="index"
          class="ayah-box ketab-font d-inline"
        >
          <p
            class="ayah-text quran_font d-inline main-color-one"
            @click="playAyahAudio(ayah.number)"
          >
            {{
              surahNo !== 1 && ayah.numberInSurah === 1
                ? ayah.text.replace(
                    "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ ",
                    ""
                  )
                : ayah.text
            }}
          </p>
          <span class="ayah-number text-center d-inline-block">{{
            convertToArabicNumber(ayah.numberInSurah)
          }}</span>
          <!-- Translation div -->
          <div v-if="!withoutTranslations">
            <div
              v-for="(ayahTranslation, index) in ayahsTranslation.filter(
                translation => translation.numberInSurah === ayah.numberInSurah
              )"
              :key="index"
              :dir="selectedTranslationDir"
              class="ayah-additional-data"
            >
              <!-- <span class="additional-data-title translation-name">
        {{ selectedTranslationLangName + ' - ' + selectedTranslationAuthorName }}
      </span> -->
              <span
                class="additional-data-content translation-content kurdish-font"
                >{{ ayahTranslation.text }}</span
              >
            </div>
          </div>

          <!-- Write Type div -->
          <div v-if="!withoutWriteTypes">
            <div
              v-for="(ayahWriteType, index) in ayahsWriteType.filter(
                writeType => writeType.numberInSurah === ayah.numberInSurah
              )"
              :key="index"
              class="ayah-additional-data"
            >
              <span class="additional-data-title write-type-name">{{
                selectedWriteTypeName
              }}</span>
              <span class="additional-data-content write-type-content">{{
                ayahWriteType.text
              }}</span>
            </div>
          </div>

          <!-- Tafsir div -->
          <div v-if="!withoutTafsirs">
            <div
              v-for="(ayahTafsir, index) in ayahsTafsir.filter(
                tafsir => tafsir.numberInSurah === ayah.numberInSurah
              )"
              :key="index"
              class="ayah-additional-data"
            >
              <span class="additional-data-title tafsir-name">{{
                selectedTafsirName
              }}</span>
              <span class="additional-data-content tafsir-content">{{
                ayahTafsir.text
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="fetchSurahFailed && !loading" class="fetch-failed text-center">
        <img
          alt="فشل جلب الآية، حاول مرة اخرى."
          class="d-block m-auto"
          src="../../assets/icons/error.png"
        />
        <p class="m-auto main-color-one">فشل فى جلب السورة</p>
        <button class="mt-3 main-btn" @click="getSurahAyahs()">
          المحاولة مرة اخرى
        </button>
      </div>
      <div v-if="loading" id="loading"></div>
    </div>
  </main>
</template>

<style scoped>
/* import font in assets/fonts/quran_fonts/ folder */
@font-face {
  font-family: "quran_font";
  src: url("../../assets/fonts/quran_fonts/ME_QURAN_VOLT_NEWMET.TTF")
    format("truetype");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "quran_font2";
  src: url("../../assets/fonts/quran_fonts/ME_QURAN_VOLT_NEWMET.TTF")
    format("truetype");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "quran_font_number";
  src: url("../../assets/fonts/quran_fonts/xat.ttf") format("truetype");
  font-weight: bolder;
  font-style: normal;
}

.quran_font {
  font-family: "quran_font", sans-serif !important;
}

.kurdish-font {
  font-family: "Speda", sans-serif !important;
}

.translation-content {
  font-size: 14px !important;
}

.main-section {
  background: #0f172a !important;
}

.main-color-one {
  color: #ffffff !important;
}

.head,
.actions,
.player-container,
#body {
  background-color: #1e293b;
  /* border-radius: 1.5rem; */
  padding: 1rem;
  text-align: right !important;
}

/* select option element */

.custom-select {
  background-color: #0f172a !important;
  /* Change the background to black */
  color: white !important;
  /* Change the text color to white */
  border-color: #0f172a !important;
  /* Optional: change the border color */

  /* Custom styling for the dropdown arrow */
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048"><path fill="%23fff" d="M2048 384v128H0V384h2048zm0 640V896H0v128h2048zm0 640v-128H0v128h2048z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  padding-right: 2rem;
  width: 100% !important;
  /* Make space for the background image */
}

/* Change the focus and hover color of the select element */
.custom-select:focus {
  border-color: #1e293b !important;
  box-shadow: 0 0 0 0.25rem #1e293b;
}

/* Style the options */
.custom-select option {
  background-color: #0f172a !important;
  color: white;
}

/* Style the optgroup label */
.custom-select optgroup {
  color: #b16d08;
}

.custom-select-black {
  background-color: #0f172a !important;
  color: white !important;
  border-color: #0f172a !important;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048"><path fill="%23fff" d="M2048 384v128H0V384h2048zm0 640V896H0v128h2048zm0 640v-128H0v128h2048z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;

  padding: 7px 35px !important;
  font-family: "quran_font", sans-serif !important;
  width: 100% !important;
}

.custom-select-black:focus {
  border-color: #1e293b !important;
  box-shadow: 0 0 0 0.25rem #1e293b;
}

.custom-select-black option {
  background-color: #0f172a !important;
  color: white;
}

.custom-select-black optgroup {
  color: #b16d08;
}

.custom-checkbox .form-check-input {
  width: 1em;
  /* Smaller width for the checkbox */
  height: 1em;
  /* Smaller height for the checkbox */
  background-color: #fff;
  /* Light background color */
  border-radius: 0.25em;
  /* Slightly rounded corners for a modern look */
  border: 1px solid #adb5bd;
  /* Subtle border styling */
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  /* Smooth transition for visual feedback */
}

.custom-checkbox .form-check-input:checked {
  background-color: #0d6efd;
  /* Bootstrap primary color for checked state */
  border-color: #0d6efd;
  /* Consistent border color with the checked background */
}

.custom-checkbox .form-check-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  /* Focus shadow with Bootstrap primary color */
}

.custom-checkbox .form-check-label {
  margin-left: 0.5rem;
  /* Space between checkbox and label */
  font-size: 0.9rem;
  /* Slightly smaller font size for the label */
  color: #343a40;
  /* Dark color for better readability */
  user-select: none;
  /* Prevent text selection */
}

.head .info-1,
.head .info-2,
.head .info-3 {
  font-size: 1.05rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.head .info-1 .number {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  background-color: #41b88326;
  font-size: 1.2rem;
}

.head .info-1 .name {
  font-size: 1.4rem;
}

.player-container.fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  border: 1px solid #eee;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.actions select,
.player-container select {
  border-radius: 1rem;
  border-color: #e4e8eb;
  font-size: 0.9rem;
}

.actions select:active,
.player-container select:active {
  border-color: #34495e;
}

.player-container input {
  cursor: pointer;
  box-shadow: none !important;
  transition: 0.2s all ease-in-out;
}

.player-container input:checked {
  background-color: #34495e;
  border-color: #34495e;
}

.player-container label {
  cursor: pointer;
  user-select: none;
  font-size: 0.7rem;
  font-weight: 700;
}

.fetch-failed p {
  font-weight: 700;
  font-size: 0.9rem;
}

#body .ayah-box {
  line-height: 2.4;
  transition: 0.2s all ease-in-out;
}

#body .ayah-box * {
  transition: 0.2s all ease-in-out;
}

#body .ayah-box:hover .ayah-text,
#body .ayah-box:hover .ayah-number {
  /* background: #222E50 !important; */
  color: #ffffff;
}

#body .ayah-box .ayah-text {
  font-size: 1.4rem;
  user-select: none;
  cursor: pointer;
}

#body .ayah-box.active .ayah-text {
  color: #ffffff;
  background: #222e50 !important;
}

#body .ayah-box .ayah-number {
  /* background-color: #0F172A; */
  /* border: 1px solid #0F172A; */
  color: #fff;
  border-radius: 50%;
  margin: 0 0.5rem;
  font-size: 3rem;
  user-select: none;
  zoom: 80% !important;
  font-family: "quran_font_number", sans-serif !important;
}

#body .ayah-box.active .ayah-number {
  color: #b16d08;
  font-weight: bolder;
}

#body .ayah-box .ayah-additional-data {
  font-size: 1.2rem;
  background-color: #0f172a;
  padding: 1rem;
  border-radius: 15px !important;
  margin: 1rem 0rem;
}

#body .ayah-box .ayah-additional-data .additional-data-title {
  margin-left: 0.5rem;
  color: #fff;
  padding: 0 0.7rem;
  border-radius: 1rem;
  display: inline-block;
  line-height: normal;
}

#body .ayah-box .ayah-additional-data[dir="ltr"] .additional-data-title {
  margin-left: 0;
  margin-right: 0.5rem;
}

#body .ayah-box .ayah-additional-data .additional-data-title.write-type-name {
  background-color: #9b59b6;
}

#body .ayah-box .ayah-additional-data .additional-data-title.tafsir-name {
  background-color: #b16d08;
}

#body .ayah-box .ayah-additional-data .additional-data-title.translation-name {
  background-color: #b84141;
}

#body .ayah-box .ayah-additional-data .additional-data-content {
  display: inline-block;
  line-height: normal;
  color: #fff;
}

@media (max-width: 576px) {
  .head .info-1 .number {
    width: 1.7rem;
    height: 1.7rem;
    line-height: 1.7rem;
    font-size: 1rem;
  }

  .head .info-1 .name {
    font-size: 1.2rem;
  }

  .head .info-2,
  .head .info-3 {
    font-size: 0.9rem;
  }

  .player-container select.responsive-font-size {
    font-size: 0.8rem;
  }
}

.back-button-parent {
  text-align: end !important;
}

.back-button {
  /* top: 20px;
  left: 20px;
  z-index: 1000; */
  background: #b16d08;
  color: #fff;
  border: none;
  padding: 9px 15px;
  zoom: 80%;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  text-align: end !important;
}
</style>

<script>
import axios from "axios"
import surahsJson from "../../assets/json/surahs.json"
import readersJson from "../../assets/json/readers.json"

export default {
  data() {
    return {
      surahNo: "",
      surahName: "",
      revelationType: "",
      numberOfAyahs: "",
      ayahs: [],
      sounds: [],
      translations: [],
      writeTypes: [],
      tafsirs: [],
      noNeededWriteType: [
        "quran-tajweed",
        "quran-wordbyword",
        "quran-kids",
        "quran-corpus-qd",
        "quran-wordbyword-2",
        "quran-uthmani-quran-academy",
        "quran-simple-enhanced",
        "quran-simple-min"
      ],
      readers: readersJson,
      surahs: surahsJson,
      withoutTranslations: true,
      withoutWriteTypes: true,
      withoutTafsirs: true,
      defaultCookiesExpireDate: "10y",
      selectedReader: this.$cookies.get("reader-id"),
      selectedTranslationAuthorName: "",
      selectedTranslationLangName: "",
      ayahsTranslation: [],
      selectedTranslationDir: "",
      selectedWriteTypeName: "",
      ayahsWriteType: [],
      selectedTafsirName: "",
      ayahsTafsir: [],
      languages: {
        ku: "Kurdish",
        en: "English",
        fa: "Persian",
        tr: "Turkish"
      },
      fetchSurahFailed: false,
      loading: true,
      currentAyah: null,
      firstAyahNumber: null,
      showScrollGoToTopBtn: false
    }
  },
  methods: {
    async getSurahAyahs(surahId, readerId = "ar.alafasy") {
      // Push surah id if not exists
      // await this.$router.push({ params: { id: surahId } })
      // Show loading
      this.loading = true
      // Get needed reader
      readerId = this.$cookies.get("reader-id") ?? readerId
      // Fetch surah data with reader audios

      try {
        axios
          .get(`https://api.alquran.cloud/v1/surah/${surahId}/${readerId}`)
          .then(data => data.data)
          .then(surah => {
            if (surah.code === 200) {
              // Assign array for make ayahs loop
              this.ayahs = surah.data.ayahs
              this.loading = false
              // Change failed status variable to false
              this.fetchSurahFailed = false
              // Set surah's simple data
              this.surahNo = surah.data.number
              this.surahName = surah.data.name
              this.revelationType = surah.data.revelationType
              this.numberOfAyahs = surah.data.numberOfAyahs
              // Get sounds
              this.getAyahSounds(this.ayahs)
              // Set surah and ayah data to the player
              this.currentAyah = surah.data.ayahs[0].number
              // Set first ayah number
              this.firstAyahNumber = surah.data.ayahs[0].number
              // Fetch surah translations, write types or tafsirs if user fetch it to the previous surah
              const change = new Event("change")
              if (this.ayahsTranslation.length > 0) {
                document
                  .getElementById("translations-selector")
                  .dispatchEvent(change)
              }
              if (this.ayahsWriteType.length > 0) {
                document
                  .getElementById("write-type-selector")
                  .dispatchEvent(change)
              }
              if (this.ayahsTafsir.length > 0) {
                document
                  .getElementById("tafsir-selector")
                  .dispatchEvent(change)
              }
            } else {
              // Change failed status variable to false
              this.fetchSurahFailed = true
            }
          })
          .then(_ => {
            this.loading = false
          })
          .then(_ => {
            this.playAyahAudio(this.currentAyah)
          })
          .catch(() => {
            // Change failed status variable to true
            this.fetchSurahFailed = true
            // Change loading status variable to false
            this.loading = false
          })
      } catch (error) {
        throw new Error(error)
      }
    },

    goBack() {
      this.$router.push("/quran")
    },

    getTranslations() {
      axios
        .get(
          "https://api.alquran.cloud/v1/edition?format=text&type=translation"
        )
        .then(data => data.data)
        .then(translations => {
          if (translations.code === 200) {
            // Assign array for make translations loop
            this.translations = translations.data
          }
        })
        .catch(e => {
          throw new Error(e)
        })
    },
    getWriteTypes() {
      axios
        .get("https://api.alquran.cloud/v1/edition/type/quran")
        .then(data => data.data)
        .then(types => {
          if (types.code === 200) {
            // Assign array for make write types loop
            this.writeTypes = types.data
          }
        })
        .catch(e => {
          throw new Error(e)
        })
    },
    getTafsirs() {
      axios
        .get("https://api.alquran.cloud/v1/edition/type/tafsir")
        .then(data => data.data)
        .then(tafsirs => {
          if (tafsirs.code === 200) {
            // Assign array for make write types loop
            this.tafsirs = tafsirs.data
          }
        })
        .catch(e => {
          throw new Error(e)
        })
    },
    getAyahsTranslations(translationId, translationOption) {
      if (translationId === "") {
        this.withoutTranslations = true
        return
      }
      const surahNo =
        this.$route.params.id.length > 0 ? this.$route.params.id : 1
      axios
        .get(`https://api.alquran.cloud/v1/surah/${surahNo}/${translationId}`)
        .then(data => data.data)
        .then(translations => {
          this.selectedTranslationAuthorName = translationOption.text
          this.selectedTranslationDir = translationOption.dataset.direction
          this.selectedTranslationLangName = translationOption.parentNode.label
          this.ayahsTranslation = translations.data.ayahs
          this.withoutTranslations = false
        })
    },
    getAyahsWriteType(WriteTypeId, WriteTypeName) {
      if (WriteTypeId === "") {
        this.withoutWriteTypes = true
        return
      }
      const surahNo =
        this.$route.params.id.length > 0 ? this.$route.params.id : 1
      axios
        .get(`https://api.alquran.cloud/v1/surah/${surahNo}/${WriteTypeId}`)
        .then(data => data.data)
        .then(WriteTypes => {
          this.selectedWriteTypeName = WriteTypeName
          this.ayahsWriteType = WriteTypes.data.ayahs
          this.withoutWriteTypes = false
        })
    },
    getAyahsTafsir(tafsirId, tafsirName) {
      if (tafsirId === "") {
        this.withoutTafsirs = true
        return
      }
      const surahNo =
        this.$route.params.id.length > 0 ? this.$route.params.id : 1
      axios
        .get(`https://api.alquran.cloud/v1/surah/${surahNo}/${tafsirId}`)
        .then(data => data.data)
        .then(tafsirs => {
          this.selectedTafsirName = tafsirName
          this.ayahsTafsir = tafsirs.data.ayahs
          this.withoutTafsirs = false
        })
    },
    convertToArabicNumber(numbers) {
      // Arabic[Hindi] numbers
      const arabicNumbers =
        "\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669"
      // Replace string and return modified value in Arabic
      return String(numbers).replace(/[0123456789]/g, d => {
        return arabicNumbers[d]
      })
    },
    getAyahSounds(obj) {
      obj.forEach(ayah => {
        this.sounds[ayah.number] = {}
        this.sounds[ayah.number]["main"] = ayah.audio
        this.sounds[ayah.number]["audioSecondary"] = ayah.audioSecondary
      })
      return this.sounds
    },
    async playAyahAudio(ayahNumber) {
      let audioSrc = this.sounds[ayahNumber].main
      // Remove highlighting from the old ayah
      this.highlightingAyah(this.currentAyah, "remove")
      // Select audio low quality source if low quality selected
      if (
        this.$cookies.get("audio-quality") === "low" &&
        this.sounds[ayahNumber].audioSecondary.length > 0
      ) {
        audioSrc =
          this.sounds[ayahNumber].audioSecondary[
            this.sounds[ayahNumber].audioSecondary.length - 1
          ]
      }
      // Handle ayah audio playing
      await document.getElementById("player").pause()
      await document.getElementById("player").setAttribute("src", audioSrc)
      document.getElementById("player").play()
      // Assign new played ayah number
      this.currentAyah = ayahNumber
      // Add highlighting to the new ayah
      this.highlightingAyah(this.currentAyah)
    },
    switchAudio() {
      // If repeat scope is ayah
      if (this.$cookies.get("repeat-scope") === "ayah") {
        return this.playAyahAudio(this.currentAyah)
      }
      // If surah ended
      if (this.firstAyahNumber + this.numberOfAyahs - 1 === this.currentAyah) {
        // If repeat scope is all or null
        if (
          this.$cookies.get("repeat-scope") === "all" ||
          this.$cookies.get("repeat-scope") === null
        ) {
          const surahNo = this.surahNo === 114 ? 1 : this.surahNo + 1
          return this.getSurahAyahs(surahNo)
        } else if (this.$cookies.get("repeat-scope") === "surah") {
          return this.playAyahAudio(this.firstAyahNumber)
        }
      }
      // Play next ayah audio
      this.playAyahAudio(this.currentAyah + 1)
    },
    highlightingAyah(ayahBoxId, action = "add") {
      const ayahBox = document.getElementById(ayahBoxId)
      if (!ayahBox) return
      if (action === "add") {
        ayahBox.classList.add("active")
      } else {
        ayahBox.classList.remove("active")
      }
    },
    changeReader(readerId) {
      this.$cookies.set("reader-id", readerId, this.defaultCookiesExpireDate)
      this.getSurahAyahs(this.surahNo, readerId)
    },
    changeRepeatScope(scope) {
      this.$cookies.set("repeat-scope", scope, this.defaultCookiesExpireDate)
    },
    changeAudioQuality(quality) {
      this.$cookies.set(
        "audio-quality",
        quality,
        this.defaultCookiesExpireDate
      )
    },
    scrollToActiveAyah(ayahId) {
      if (this.$cookies.get("tracking-ayah") !== "true") return
      window.scrollTo({
        top:
          document.getElementById(ayahId).getBoundingClientRect().top +
          window.scrollY -
          document.getElementById("main-nav").scrollHeight -
          20,
        behavior: "smooth"
      })
    },
    trackingAyah(tracking) {
      if (tracking) {
        this.$cookies.set(
          "tracking-ayah",
          tracking,
          this.defaultCookiesExpireDate
        )
        this.scrollToActiveAyah(this.currentAyah)
      } else {
        this.$cookies.set(
          "tracking-ayah",
          tracking,
          this.defaultCookiesExpireDate
        )
      }
    }
  },
  mounted() {
    // Fetch surah ayahs
    this.getSurahAyahs(
      this.$route.params.id !== "" ? this.$route.params.id : 1
    )
    // Fetch translations
    this.getTranslations()
    // Fetch write types
    this.getWriteTypes()
    // Fetch tasfirs
    this.getTafsirs()
    // Make player container is fixed when it hides at big scroll
    const playerContainerHeight =
      document.getElementById("player-container").clientHeight + 16 // 16 is the margin top of this element
    window.addEventListener("scroll", () => {
      if (this.$route.name === "quran") {
        const bodyTop =
          document.getElementById("body").getBoundingClientRect().top -
          document.getElementById("main-nav").scrollHeight -
          20
        if (bodyTop < 0) {
          document.getElementById("player-container").classList.add("fixed")
          document.getElementsByTagName(
            "body"
          )[0].style.margin = `${playerContainerHeight}px 0`
          this.showScrollGoToTopBtn = true
        } else {
          document.getElementById("player-container").classList.remove("fixed")
          document.getElementsByTagName("body")[0].style.margin = 0
          this.showScrollGoToTopBtn = false
        }
      } else {
        document.getElementsByTagName("body")[0].style.margin = 0
      }
    })
  }
}
</script>
