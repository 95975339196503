import "@babel/polyfill"
import Vue from "vue"
import "mutationobserver-shim"
import "./Utils/fliter"
import App from "./App.vue"
import router from "./router"
import store from "./store.js"
import Raphael from "raphael/raphael"
import "./plugins"
import "./registerServiceWorker"
import i18n from "./i18n"
import "./directives"
import AlertServie from "./services/errors"
import ApiService from "./services/api"
import VuePlyr from "vue-plyr"
import "vue-plyr/dist/vue-plyr.css"
import VueCookies from "vue-cookies"
import VueGeolocation from "vue-browser-geolocation"

Vue.use(VueGeolocation)

global.Raphael = Raphael

// Vue.config.productionTip = false;
ApiService.init()
AlertServie.init()

Vue.use(VuePlyr, {
  plyr: {}
})

Vue.use(VueCookies)

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app")

window.vm = vm
